import React from 'react';
import { OrderPaymentStatus } from '@dtp/membership-service-types';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { TextList, TextListItem } from '@naf/text-list';
import { useOrder } from '../../../../hooks/useOrder';
import { useSendGTMEventOnceForId } from '../../../../hooks/GTM/useSendGTMEventOnceForId';
import { NAFGTMEvent } from '../../../../../../types/GTM/NAFGTMEvent';
import { LayoutWithMainContent } from '../../../../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../../../../lib/constants';
import Loader from '../../../../components/loader';
import { formattedPrice } from '../../../../lib/formattedPrice';
import ReceiptIllustration from '../../../../../assets/images/ReceiptIllustration.svg?url';
import { waitingCodes } from '../../../../hooks/useRunningUpdates';
import BlockContent from '../../../../components/block-content/BlockContent';
import { InternalSearchCardType } from '../../../../components/layout/InternalSearchMetaData';
import * as S from './styles';
import { SectionBlocks } from '../../../../components/SectionBlocks';
import FeedbackSurvey from '../../../../components/feedbacksurvey/FeedbackSurvey';
import { useWindowLocation } from '../../../../hooks/Window/useWindowLocation';

interface IdpReceiptProps {
  match: { params: { orderId: string }; url: string };
}

export const IdpReceipt = ({
  match: {
    params: { orderId },
  },
}: IdpReceiptProps) => {
  const order = useOrder(orderId);
  const {
    summary: { orderSummaries, email, grandTotal, campaignCode },
  } = order || { summary: {} };
  const windowPath = useWindowLocation();

  useSendGTMEventOnceForId(
    order &&
      orderSummaries && {
        event: NAFGTMEvent.purchase,
        ecommerce: {
          value: grandTotal,
          coupon: campaignCode,
          transaction_id: orderId,
          items: orderSummaries.map((item) => ({
            item_name: item.productName,
            item_id: item.productNumber,
            price: item.productPrice.toFixed(2),
            quantity: item.number.toString(),
            item_category2: 'Internasjonalt Førerkort',
            item_category3: 'Bestill',
          })),
        },
      },
    [order],
    `purchase-event-${orderId}`,
    order?.paymentOrderStatus === OrderPaymentStatus.PAID,
  );

  const content = order?.productPaymentOutcome?.paymentReceipt?.content || [];

  return (
    <LayoutWithMainContent
      title="Ditt kjøp er gjennomført"
      description="Du har bestilt internasjonalt førerkort"
      imgUrl={DEFAULT_IMG_URL}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <S.Container>
        {!order ||
          (waitingCodes.includes(order.status) && order.paymentOrderStatus === OrderPaymentStatus.PAID && (
            <Grid>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.Header variant={TextVariant.Header1}>Vi behandler ditt kjøp</S.Header>
              </GridCol>
              <GridCol s="12" m="12" l="12" xl="12">
                <img src={ReceiptIllustration} alt="NAF Illustrasjon" />
              </GridCol>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.LoaderWrapper>
                  <Loader />
                </S.LoaderWrapper>
                <S.AnimatedText variant={TextVariant.Header3}>
                  Takk for din betaling. Vi forbereder din kjøpskvittering.
                </S.AnimatedText>
              </GridCol>
            </Grid>
          ))}
        {order && !waitingCodes.includes(order.status) && order.paymentOrderStatus === OrderPaymentStatus.PAID && (
          <Grid>
            <GridCol s="12" m="12" l="12" xl="12">
              <BlockContent value={order.productPaymentOutcome?.paymentReceipt?.body} />
            </GridCol>
            <GridCol s="12" m="12" l="7" xl="7">
              <img src={ReceiptIllustration} alt="NAF Illustrasjon" />
              <Text>Vi har sendt deg en e-post til {email}, som bekrefter din bestilling.</Text>
              <Text variant={TextVariant.Header3}>
                {order.productPaymentOutcome?.paymentReceipt?.stepByStep?.title}
              </Text>
              <TextList>
                {order.productPaymentOutcome?.paymentReceipt?.stepByStep?.list.map((item) => (
                  <TextListItem key={item.title}>
                    <Text variant={TextVariant.Header3}>{item.title}</Text>
                    {item.body.map((block) => (
                      <BlockContent key={block._key} value={[block]} />
                    ))}
                  </TextListItem>
                ))}
              </TextList>
            </GridCol>
            {orderSummaries && (
              <S.SummaryCol s="12" m="12" l="4" xl="4">
                <S.Summary>
                  <Text variant={TextVariant.Header3}>Ditt kjøp</Text>
                  <Text variant={TextVariant.SubHeader}>Internasjonalt førerkort</Text>
                  <S.Products>
                    {orderSummaries.map((productLine, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <S.ProductLine key={`${productLine.productNumber}-${index}`}>
                        <Text variant={TextVariant.ArticleTextHeader}>{productLine.productName}</Text>
                        <Text variant={TextVariant.ArticleTextHeader}>
                          {formattedPrice(productLine.productPrice)} kr
                        </Text>
                      </S.ProductLine>
                    ))}
                  </S.Products>
                  {grandTotal && (
                    <S.Total>
                      <Text variant={TextVariant.ArticleTextHeader}>Totalt</Text>
                      <Text variant={TextVariant.ArticleTextHeader}>{formattedPrice(grandTotal)} kr</Text>
                    </S.Total>
                  )}
                </S.Summary>
              </S.SummaryCol>
            )}
            {content.length > 0 && (
              <>
                <GridRow>
                  <S.ContentWrapper s="12" m="12" l="12" xl="12">
                    <SectionBlocks content={content} />
                  </S.ContentWrapper>
                </GridRow>
                <GridRow>
                  <GridCol s="12" m="12" l="8" xl="8">
                    <FeedbackSurvey contentUrl={windowPath} />
                  </GridCol>
                </GridRow>
              </>
            )}
          </Grid>
        )}
        {order &&
          (order.paymentOrderStatus === OrderPaymentStatus.CANCELED ||
            order.paymentOrderStatus === OrderPaymentStatus.NONE) && (
            <>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.Header variant={TextVariant.Header1}>Du avbrøt betalingen!</S.Header>
              </GridCol>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.Wrapper>
                  <BlockContent value={order.productPaymentOutcome?.paymentCanceled?.body} />
                </S.Wrapper>
              </GridCol>
            </>
          )}
      </S.Container>
    </LayoutWithMainContent>
  );
};
